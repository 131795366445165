import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  Container,
  StyledForm,
  StyledFormControl,
  TextBox,
  SendButton,
  FormBox,
  BottomBox,
} from "../components/styles";
import { User } from "../components/types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import { useAPI } from "../service/api";
import useUserStore from "../store/userStore";
import useAuthStore from "../store/authStore";
import { toast } from "react-toastify";

const RegistrationForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [name_kana, setFurigana] = useState<string>("");
  type Gender = "male" | "female" | "other";
  const [gender, setGender] = useState<Gender>("male");
  const [birth_month, setBirthMonth] = useState<string>("1月");
  const [phone_number, setPhoneNumber] = useState<string>("");
  const api = useAPI();
  const { setUser } = useUserStore();
  const { setIsAuthenticated } = useAuthStore();
  const [nameError, setNameError] = useState<string | null>(null);
  const [nameKanaError, setNameKanaError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const maxLengthName = 30;
  const maxLengthNameKana = 60;
  const navigate = useNavigate();

  const mutation = useMutation<User, Error, User>({
    mutationFn: async (userData) => {
      try {
        const response: User = await api.create("users", userData);
        return response;
      } catch (error: any) {
        let errorMessage: string;

        if (error.response && error.response.status === 401) {
          errorMessage = "認証エラーが発生しました 再度ログインしてください";
          liff.logout();
          liff.login();
        } else if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              if (error.response.data) {
                const errors = error.response.data;
                const errorFields = Object.keys(errors).filter(
                  (key) => errors[key] && errors[key].length > 0
                );

                if (errorFields.length > 0) {
                  const mentionedFields = errorFields
                    .map((field) => {
                      switch (field) {
                        case "name":
                          return "名前";
                        case "name_kana":
                          return "フリガナ";
                        case "phone_number":
                          return "電話番号";
                        default:
                          return field;
                      }
                    })
                    .join("  ");
                  errorMessage = `入力内容を確認してください (${mentionedFields})`;
                } else {
                  errorMessage = "入力内容を確認し、再度お試しください。";
                }
              } else {
                errorMessage = "入力内容を確認し、再度お試しください。";
              }
              break;
            case 404:
              errorMessage = "リクエストされたページが見つかりません";
              break;
            case 500:
              errorMessage =
                "サーバーエラーが発生しました しばらくしてから再度お試しください";
              break;
            default:
              errorMessage = `APIエラーが発生しました (ステータスコード: ${statusCode})`;
              if (error.response.data) {
                if (error.response.data.detail) {
                  errorMessage += ` 詳細: ${error.response.data.detail}`;
                } else if (error.response.data.message) {
                  errorMessage += ` 詳細: ${error.response.data.message}`;
                } else if (typeof error.response.data === "string") {
                  errorMessage += ` 詳細: ${error.response.data}`;
                } else {
                  toast.error("APIエラー詳細:", error.response.data);
                  errorMessage += " 詳細は管理者に問い合わせてください。";
                }
              } else {
                errorMessage += " 詳細な情報は取得できませんでした。";
              }
              break;
          }
        } else {
          errorMessage =
            "ネットワークエラーが発生しました インターネット接続を確認し、再度お試しください";
        }

        toast.error(errorMessage);
        console.error("会員登録エラー:", error);
        throw error;
      }
    },
    onSuccess: (data: User) => {
      console.log("会員登録成功:", data);
      setIsAuthenticated(true);
      setUser(data);
      navigate("/mypage");
      toast.success("会員登録成功");
    },

    onError: (error: Error) => {
      console.error("会員登録エラー:", error);
      toast.error("登録に失敗しました");
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userData: User = {
      name,
      name_kana,
      gender,
      birth_month,
      phone_number,
    };

    mutation.mutate(userData);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    setNameError(
      value.length > maxLengthName
        ? `${maxLengthName}文字以内にしてください 現在${value.length}文字です`
        : null
    );
  };

  const handleNameKanaChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFurigana(value);
    setNameKanaError(
      value.length > maxLengthNameKana
        ? `${maxLengthNameKana}文字以内にしてください 現在${value.length}文字です`
        : null
    );
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhoneNumber(value);

    let errorText = null;
    if (numericValue.length > 20) {
      errorText = `20桁以内にしてください 現在${numericValue.length}桁です`;
    } else if (value !== numericValue) {
      errorText = "数字のみを入力してください";
    }

    setPhoneNumberError(errorText);
  };

  return (
    <Container>
      <TextBox>
        登録いただくと会員価格で
        <br />
        お召し上がりいただけます
      </TextBox>
      <FormBox>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="名前"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            required
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            id="furigana"
            label="フリガナ"
            variant="outlined"
            value={name_kana}
            onChange={handleNameKanaChange}
            required
            error={!!nameKanaError}
            helperText={nameKanaError}
          />
          <StyledFormControl variant="outlined">
            <InputLabel id="gender-label">性別</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value as Gender)}
              label="性別"
            >
              <MenuItem value="male">男性</MenuItem>
              <MenuItem value="female">女性</MenuItem>
              <MenuItem value="other">その他</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined">
            <InputLabel id="birthMonth-label">お誕生月</InputLabel>
            <Select
              labelId="birthMonth-label"
              id="birthMonth"
              value={birth_month}
              onChange={(e) => setBirthMonth(e.target.value)}
              label="お誕生月"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={`${i + 1}月`}>
                  {i + 1}月
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            id="phone_number"
            name="phone_number"
            label="電話番号 (数字のみ)"
            type="tel"
            variant="outlined"
            value={phone_number}
            onChange={handlePhoneNumberChange}
            required
            error={!!phoneNumberError}
            helperText={phoneNumberError}
          />

          <SendButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!!nameError || !!nameKanaError || !!phoneNumberError}
          >
            送信
          </SendButton>
        </StyledForm>
      </FormBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default RegistrationForm;
