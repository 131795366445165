import React, { useState, useEffect, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  Container,
  StyledForm,
  StyledFormControl,
  TextBox,
  SendButton,
  FormBox,
  BottomBox,
} from "../components/styles";

import { useNavigate } from "react-router-dom";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";
import useUserStore from "../store/userStore";
import { User } from "../components/types";
import { toast } from "react-toastify";

export const updateUserData = async (userData: User): Promise<User> => {
  const response = await axios.put<User>(`/users/${userData.id}`, userData);
  console.log(response.data);
  return response.data;
};

const EditProfileForm: React.FC = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useUserStore();
  const [userData, setUserData] = useState<User>({
    id: "",
    name: "",
    name_kana: "",
    gender: "male",
    birth_month: "1月",
    phone_number: "",
    point: 0,
    create_date: "",
    line_id: "",
  });
  const [nameError, setNameError] = useState<string | null>(null);
  const [nameKanaError, setNameKanaError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const maxLengthName = 30;
  const maxLengthNameKana = 60;

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
    setIsLoading(false);
  }, [user]);

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const target = event.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    const value = target.value;
    const name = target.name;

    switch (name) {
      case "name":
        setNameError(
          value.length > maxLengthName
            ? `${maxLengthName}文字以内にしてください。現在${value.length}文字です。`
            : null
        );
        break;
      case "name_kana":
        setNameKanaError(
          value.length > maxLengthNameKana
            ? `${maxLengthNameKana}文字以内にしてください。現在${value.length}文字です。`
            : null
        );
        break;
      case "phone_number":
        const numericValue = value.replace(/\D/g, "");
        setPhoneNumberError(
          numericValue.length > 20
            ? `20文字以内にしてください。現在${numericValue.length}文字です。`
            : null
        );
        break;
      default:
        break;
    }

    setUserData({
      ...userData,
      [target.name as keyof User]:
        target.name === "phone_number" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!userData.id) {
        throw new Error("User ID is missing");
      }
      const response: User = await api.update("users", userData.id, userData);
      console.log(response);
      setUser(response);
      toast.success("会員情報更新成功");
      navigate("/mypage");
    } catch (error: any) {
      console.error("会員情報更新エラー:", error);
      toast.error("更新に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <TextBox>Loding</TextBox>
        <BottomBox></BottomBox>
      </Container>
    );
  }

  return (
    <Container>
      <TextBox>会員情報編集</TextBox>
      <FormBox>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            label="名前"
            variant="outlined"
            value={userData.name}
            required
            onChange={handleChange}
            error={!!nameError}
            helperText={nameError}
            sx={{ mb: 2 }}
          />
          <TextField
            id="name_kana"
            name="name_kana"
            label="フリガナ"
            variant="outlined"
            value={userData.name_kana}
            required
            onChange={handleChange}
            error={!!nameKanaError}
            helperText={nameKanaError}
            sx={{ mb: 2 }}
          />
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="gender-label">性別</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              name="gender"
              value={userData.gender}
              onChange={(event: any) => handleChange(event)}
              label="性別"
            >
              <MenuItem value="male">男性</MenuItem>
              <MenuItem value="female">女性</MenuItem>
              <MenuItem value="other">その他</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="birthMonth-label">お誕生月</InputLabel>
            <Select
              labelId="birthMonth-label"
              id="birthMonth"
              name="birth_month"
              value={userData.birth_month}
              onChange={(event: any) => handleChange(event)}
              label="お誕生月"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={`${i + 1}月`}>
                  {i + 1}月
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            id="phone_number"
            name="phone_number"
            type="tel"
            label="電話番号"
            variant="outlined"
            value={userData.phone_number}
            onChange={handleChange}
            required
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            sx={{ mb: 2 }}
          />

          <SendButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!!nameError || !!nameKanaError || !!phoneNumberError}
          >
            更新
          </SendButton>
        </StyledForm>
      </FormBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default EditProfileForm;
