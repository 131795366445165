import React, { useEffect, useState } from "react";
import {
  Container,
  TextBox,
  AddButton,
  Stamp,
  StampContainer,
  StampNumber,
  ListButton,
  BottomBox,
  SubTextBox,
  CustomDialogTitle,
} from "../components/styles";
import AddIcon from "@mui/icons-material/Add";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";
import dayjs from "dayjs";
import QRCodeScanner from "../components/QRCodeScanner";
import { UserStampCard, QRcode } from "../components/types";
import useUserStore from "../store/userStore";

const StampCard: React.FC = () => {
  const api = useAPI();
  const [stamps, setStamps] = useState<boolean[]>([]);
  const [stampImages, setStampImages] = useState<{ imageUrl: string }[]>([]);
  const [scanError, setScanError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState<string | undefined>();
  const [qrCodeData, setQrCodeData] = useState<string | undefined>();
  const [activeUserStampCard, setActiveUserStampCard] =
    useState<UserStampCard | null>(null);
  const navigate = useNavigate();
  const numColumns = 5;
  const [numRows, setNumRows] = useState(0);
  const [stampMessage, setStampMessage] = useState<string | null>(null);
  const location = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const initializeLiffAndFetchData = async () => {
      const cardId = location.state?.toast?.status;
      try {
        let response = null;
        if (cardId) {
          response = await api.getOne<UserStampCard>("user-stamp-card", cardId);
        } else {
          response = await api.getWithAction<UserStampCard>(
            "user-stamp-card",
            "active"
          );
        }
        if (!response || !response.stamp_card) {
          navigate("/stamp/index");
          return;
        }

        if (
          response.stamp_card?.max_stamps <= response.current_stamps &&
          response.is_active
        ) {
          navigate("/stamp/index");
          return;
        }

        setActiveUserStampCard(response || null);

        const initialStamps = Array(response.stamp_card.max_stamps).fill(false);
        for (let i = 0; i < response.current_stamps; i++) {
          initialStamps[i] = true;
        }
        setStamps(initialStamps);

        const newStampImages = Array.isArray(response.stamp_card.stamp_image)
          ? response.stamp_card.stamp_image.map((imageUrl: any) => ({
              imageUrl,
            }))
          : [{ imageUrl: response.stamp_card.stamp_image }];

        setStampImages(newStampImages);

        setNumRows(Math.ceil(response.stamp_card.max_stamps / numColumns));
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate("/stamp/index");
        } else if (error.response?.status === 500) {
          navigate("/stamp/index");
        } else {
          console.error("スタンプカード取得エラー:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    initializeLiffAndFetchData();
  }, [api, navigate, numColumns, location]);

  const getQR = async () => {
    try {
      const response: QRcode[] = await api.get(`qrcode`);
      const qrCode = response[0].code;
      setCode(qrCode);

      setQrCodeData(undefined);
      setOpen(true);
    } catch (error) {
      console.error("error", error);
      setScanError("QRコードスキャン中にエラーが発生しました");
    }
  };

  const handleScan = (data: string) => {
    setQrCodeData(data);
    setOpen(false);
  };

  useEffect(() => {
    const getStamp = async () => {
      try {
        const response = await axios.post(
          "/user-stamp-history",
          {
            user_stamp_card: activeUserStampCard?.id,
          },
          { withCredentials: true }
        );
        if (typeof response.data.point === "number") {
          useUserStore.setState((state: { user: any }) => ({
            user: { ...state.user, point: response.data.point },
          }));

          if (activeUserStampCard) {
            const nextStampIndex = stamps.findIndex((stamp) => !stamp);
            if (nextStampIndex !== -1) {
              const newStamps = [...stamps];
              newStamps[nextStampIndex] = true;
              setStamps(newStamps);

              setActiveUserStampCard({
                ...activeUserStampCard,
                current_stamps: activeUserStampCard.current_stamps + 1,
              });
              setStampMessage("スタンプが押されました！");
            }
          }
        } else {
          setScanError("本日既にスタンプを取得しています");
        }
      } catch (error: any) {
        setScanError("読み込みでエラーが発生しました");
      }
    };

    if (qrCodeData) {
      if (qrCodeData === code) {
        getStamp();
      } else {
        setScanError("QRコードが一致しません");
      }
    }
  }, [qrCodeData, code]);

  const currentStamps = activeUserStampCard?.current_stamps ?? 0;
  const maxStamps = activeUserStampCard?.stamp_card?.max_stamps ?? 0;

  const handleGetStamp = () => {
    if (currentStamps < maxStamps) {
      setScanError(null);
      setStampMessage(null);
      // getStamp();
      getQR();
    } else {
      handleNavigate();
    }
  };

  const handleNavigate = () => {
    navigate("/stamp/index");
  };

  if (isLoading) {
    return (
      <Container>
        <TextBox>Loding</TextBox>
        <BottomBox></BottomBox>
      </Container>
    );
  }

  return (
    <Container>
      <TextBox>スタンプカード</TextBox>

      <StampContainer numRows={numRows}>
        {stamps.map((isStamped, i) => (
          <Stamp key={i} isStamped={isStamped} number={i + 1}>
            {isStamped && (
              <img
                src={stampImages[i % stampImages.length].imageUrl}
                alt={`Stamp ${i + 1}`}
                style={{ width: "30px", height: "30px" }}
              />
            )}
            {!isStamped && <StampNumber>{i + 1}</StampNumber>}
          </Stamp>
        ))}
      </StampContainer>
      {stampMessage && (
        <SubTextBox sx={{ width: "80%", marginTop: "30px" }}>
          <Typography variant="body1" color="success">
            {stampMessage}
          </Typography>
        </SubTextBox>
      )}
      {scanError && (
        <SubTextBox sx={{ width: "80%", marginTop: "30px" }}>
          <Typography variant="body1" color="error">
            {scanError}
          </Typography>
        </SubTextBox>
      )}
      {currentStamps < maxStamps ? (
        <>
          <SubTextBox
            sx={{ padding: "10px 10px 20px 20px", marginTop: "30px" }}
            textAlign={"left"}
          >
            <Typography variant="body1">
              右下の
              <Typography
                component="span"
                color="primary"
                onClick={handleGetStamp}
              >
                青いボタン
              </Typography>
              を押して
            </Typography>
            <Typography variant="body1">
              QRコードを読み込んでください
            </Typography>
            <Typography variant="body1">
              スタンプを押すとポイントがたまります
            </Typography>
            <Typography variant="body1">
              ポイントはクーポンと交換できます
            </Typography>
            <Typography variant="body1">スタンプは一日一回限りです</Typography>
          </SubTextBox>
        </>
      ) : (
        <>
          <SubTextBox
            sx={{ padding: "10px 10px 20px 20px", marginTop: "30px" }}
            textAlign={"left"}
          >
            <Typography variant="body1">スタンプがいっぱいです</Typography>
            <Typography variant="body1">
              新しいカードを獲得・使用してください
            </Typography>
            <Typography variant="body1">
              {activeUserStampCard?.end_at &&
                `★  完了日：${dayjs(activeUserStampCard?.end_at).format(
                  "YYYY-MM-DD"
                )}`}
            </Typography>
          </SubTextBox>
        </>
      )}

      <Box sx={{ width: "80%" }}>
        <ListButton
          startIcon={<MenuIcon />}
          onClick={handleNavigate}
          sx={{ marginTop: "5px" }}
        >
          スタンプカード一覧
        </ListButton>
      </Box>
      {currentStamps < maxStamps ? (
        <AddButton onClick={handleGetStamp}>
          <AddIcon sx={{ width: "40px", height: "40px" }} />
        </AddButton>
      ) : (
        <AddButton onClick={handleGetStamp}>
          <UTurnLeftIcon
            sx={{ width: "40px", height: "40px", transform: "rotate(90deg)" }}
          />
        </AddButton>
      )}
      <Dialog open={open}>
        <CustomDialogTitle>QRコード読み取り</CustomDialogTitle>
        <DialogContent sx={{ margin: "0px 20px" }}>
          <QRCodeScanner onScan={handleScan}></QRCodeScanner>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ fontSize: "12px" }}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>

      <BottomBox></BottomBox>
    </Container>
  );
};

export default StampCard;
